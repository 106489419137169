// @formatter:off
import {Regex} from "./GeneratedTypes";

export interface MapModifierT17TokenOption {
  tier17: boolean,
  scary: number,
}
export const regexMapModifierT17: Regex<MapModifierT17TokenOption> = {
  tokens: [
    {id: -2106392014, regex: "o al", rawText: "Players have -20% to all maximum Resistances", generalizedText: "^players have -#% to all maximum resistances$", options: {tier17: true, scary: 2170}},
    {id: -2103899765, regex: "kes", rawText: "Monsters take (36-40)% reduced Extra Damage from Critical Strikes", generalizedText: "^monsters take #% reduced extra damage from critical strikes$", options: {tier17: false, scary: 250}},
    {id: -2068532865, regex: "'v", rawText: "Players have 3% reduced Action Speed for each time they've used a Skill Recently", generalizedText: "^players have #% reduced action speed for each time they've used a skill recently$", options: {tier17: true, scary: 1189}},
    {id: -2066574800, regex: "ra c", rawText: "Monsters gain (80-100)% of their Physical Damage as Extra Chaos Damage", generalizedText: "^monsters gain #% of their physical damage as extra chaos damage$", options: {tier17: true, scary: 1667}},
    {id: -2060936381, regex: "poss", rawText: "Unique Bosses are Possessed", generalizedText: "^unique bosses are possessed$", options: {tier17: true, scary: 240}},
    {id: -1994088653, regex: "r damage$", rawText: "(30-40)% increased Monster Damage", generalizedText: "^#% increased monster damage$", options: {tier17: true, scary: 1602}},
    {id: -1952553467, regex: "ks h", rawText: "Monsters' Attacks have 60% chance to Impale on Hit", generalizedText: "^monsters' attacks have #% chance to impale on hit$", options: {tier17: false, scary: 98}},
    {id: -1942271057, regex: "r damage$", rawText: "(22-25)% increased Monster Damage", generalizedText: "^#% increased monster damage$", options: {tier17: false, scary: 370}},
    {id: -1926268187, regex: "ms$", rawText: "Area contains many Totems", generalizedText: "^area contains many totems$", options: {tier17: false, scary: 9}},
    {id: -1869263008, regex: "teal", rawText: "Monsters steal Power, Frenzy and Endurance charges on Hit", generalizedText: "^monsters steal power, frenzy and endurance charges on hit$", options: {tier17: false, scary: 80}},
    {id: -1842810065, regex: "hap", rawText: "Rare monsters in area are Shaper-Touched", generalizedText: "^rare monsters in area are shaper-touched$", options: {tier17: true, scary: 1185}},
    {id: -1834985050, regex: "vu", rawText: "Players are Cursed with Vulnerability|Players are Cursed with Temporal Chains|Players are Cursed with Elemental Weakness", generalizedText: "^players are cursed with vulnerability$|^players are cursed with temporal chains$|^players are cursed with elemental weakness$", options: {tier17: true, scary: 1557}},
    {id: -1781581610, regex: "bom", rawText: "Area is inhabited by Abominations", generalizedText: "^area is inhabited by abominations$", options: {tier17: false, scary: 4}},
    {id: -1772227908, regex: "solar", rawText: "Area is inhabited by Solaris fanatics", generalizedText: "^area is inhabited by solaris fanatics$", options: {tier17: false, scary: 4}},
    {id: -1705341052, regex: "enf", rawText: "Players are Cursed with Enfeeble", generalizedText: "^players are cursed with enfeeble$", options: {tier17: false, scary: 360}},
    {id: -1678942184, regex: "nsec", rawText: "Area has patches of Consecrated Ground", generalizedText: "^area has patches of consecrated ground$", options: {tier17: false, scary: 310}},
    {id: -1660701657, regex: "get", rawText: "Players are targeted by a Meteor when they use a Flask", generalizedText: "^players are targeted by a meteor when they use a flask$", options: {tier17: true, scary: 1404}},
    {id: -1636388574, regex: "uct", rawText: "+50% Monster Physical Damage Reduction|+35% Monster Chaos Resistance|+55% Monster Elemental Resistances", generalizedText: "^#% monster physical damage reduction$|^#% monster chaos resistance$|^#% monster elemental resistances$", options: {tier17: true, scary: 1379}},
    {id: -1621229119, regex: "om$", rawText: "Monsters cannot be Leeched from", generalizedText: "^monsters cannot be leeched from$", options: {tier17: false, scary: 600}},
    {id: -1514062481, regex: "mav", rawText: "The Maven interferes with Players", generalizedText: "^the maven interferes with players$", options: {tier17: true, scary: 1184}},
    {id: -1505174556, regex: "of m", rawText: "Monsters gain (70-80)% of Maximum Life as Extra Maximum Energy Shield", generalizedText: "^monsters gain #% of maximum life as extra maximum energy shield$", options: {tier17: true, scary: 1453}},
    {id: -1462808973, regex: "lier", rawText: "Monsters have (360-400)% increased Critical Strike Chance|+(41-45)% to Monster Critical Strike Multiplier", generalizedText: "^monsters have #% increased critical strike chance$|^+#% to monster critical strike multiplier$", options: {tier17: false, scary: 500}},
    {id: -1403235912, regex: "ur$", rawText: "Players have 30% less Armour|Players have 40% reduced Chance to Block", generalizedText: "^players have #% less armour$|^players have #% reduced chance to block$", options: {tier17: false, scary: 295}},
    {id: -1331734856, regex: "lid", rawText: "Monsters' skills Chain 3 additional times|Monsters' Projectiles can Chain when colliding with Terrain", generalizedText: "^monsters' skills chain # additional times$|^monsters' projectiles can chain when colliding with terrain$", options: {tier17: true, scary: 1640}},
    {id: -1321541749, regex: "uns", rawText: "Area contains Unstable Tentacle Fiends", generalizedText: "^area contains unstable tentacle fiends$", options: {tier17: true, scary: 1222}},
    {id: -1175378302, regex: "hum", rawText: "Area is inhabited by Humanoids", generalizedText: "^area is inhabited by humanoids$", options: {tier17: false, scary: 4}},
    {id: -1129414698, regex: "mum p", rawText: "Monsters have +1 to Maximum Power Charges|Monsters gain a Power Charge on Hit", generalizedText: "^monsters have # to maximum power charges$|^monsters gain a power charge on hit$", options: {tier17: true, scary: 1288}},
    {id: -1124347635, regex: "rune", rawText: "Area contains Runes of the Searing Exarch", generalizedText: "^area contains runes of the searing exarch$", options: {tier17: true, scary: 1223}},
    {id: -1121411477, regex: "old$", rawText: "Monsters deal (90-110)% extra Physical Damage as Cold", generalizedText: "^monsters deal #% extra physical damage as cold$", options: {tier17: false, scary: 450}},
    {id: -1121327683, regex: "fire$", rawText: "Monsters deal (90-110)% extra Physical Damage as Fire", generalizedText: "^monsters deal #% extra physical damage as fire$", options: {tier17: false, scary: 450}},
    {id: -1036254613, regex: "zy c", rawText: "Monsters gain a Frenzy Charge on Hit", generalizedText: "^monsters gain a frenzy charge on hit$", options: {tier17: false, scary: 80}},
    {id: -1033371824, regex: "thr", rawText: "Player Skills which Throw Mines throw 1 fewer Mine|Player Skills which Throw Traps throw 1 fewer Trap", generalizedText: "^player skills which throw mines throw # fewer mine$|^player skills which throw traps throw # fewer trap$", options: {tier17: true, scary: 1199}},
    {id: -926892006, regex: "of m", rawText: "Monsters gain (40-49)% of Maximum Life as Extra Maximum Energy Shield", generalizedText: "^monsters gain #% of maximum life as extra maximum energy shield$", options: {tier17: false, scary: 240}},
    {id: -894195682, regex: "tiles$", rawText: "Monsters have 100% increased Area of Effect|Monsters fire 2 additional Projectiles", generalizedText: "^monsters have #% increased area of effect$|^monsters fire # additional projectiles$", options: {tier17: true, scary: 1645}},
    {id: -818452956, regex: "gen", rawText: "Players cannot Regenerate Life, Mana or Energy Shield", generalizedText: "^players cannot regenerate life, mana or energy shield$", options: {tier17: false, scary: 700}},
    {id: -710235370, regex: "wak", rawText: "Area has patches of Awakeners' Desolation", generalizedText: "^area has patches of awakeners' desolation$", options: {tier17: true, scary: 1531}},
    {id: -704095924, regex: "r li", rawText: "(90-100)% more Monster Life", generalizedText: "^#% more monster life$", options: {tier17: true, scary: 1330}},
    {id: -696963131, regex: "f el", rawText: "Monsters reflect 18% of Elemental Damage", generalizedText: "^monsters reflect #% of elemental damage$", options: {tier17: false, scary: 1000}},
    {id: -669857265, regex: "efe", rawText: "Players have (25-30)% less Defences", generalizedText: "^players have #% less defences$", options: {tier17: true, scary: 1192}},
    {id: -620427144, regex: "net", rawText: "Monster Damage Penetrates 15% Elemental Resistances", generalizedText: "^monster damage penetrates #% elemental resistances$", options: {tier17: true, scary: 1217}},
    {id: -529767307, regex: "taun", rawText: "Monsters cannot be Taunted|Monsters' Action Speed cannot be modified to below Base Value|Monsters' Movement Speed cannot be modified to below Base Value", generalizedText: "^monsters cannot be taunted$|^monsters' action speed cannot be modified to below base value$|^monsters' movement speed cannot be modified to below base value$", options: {tier17: false, scary: 89}},
    {id: -525989649, regex: "ld f", rawText: "Rare and Unique Monsters remove 5% of Life, Mana and Energy Shield from Players or their Minions on Hit", generalizedText: "^rare and unique monsters remove #% of life, mana and energy shield from players or their minions on hit$", options: {tier17: true, scary: 1186}},
    {id: -500015770, regex: "imes", rawText: "Monsters' skills Chain 2 additional times", generalizedText: "^monsters' skills chain # additional times$", options: {tier17: false, scary: 380}},
    {id: -474049160, regex: "mum f", rawText: "Monsters have +1 to Maximum Frenzy Charges|Monsters gain a Frenzy Charge on Hit", generalizedText: "^monsters have # to maximum frenzy charges$|^monsters gain a frenzy charge on hit$", options: {tier17: true, scary: 1289}},
    {id: -412180168, regex: "f ph", rawText: "Monsters reflect 20% of Physical Damage|Monsters reflect 20% of Elemental Damage", generalizedText: "^monsters reflect #% of physical damage$|^monsters reflect #% of elemental damage$", options: {tier17: true, scary: 2203}},
    {id: -380667604, regex: "pet", rawText: "Area contains Petrification Statues", generalizedText: "^area contains petrification statues$", options: {tier17: true, scary: 1224}},
    {id: -309278236, regex: "blin", rawText: "Monsters Blind on Hit", generalizedText: "^monsters blind on hit$", options: {tier17: false, scary: 50}},
    {id: -208379648, regex: "tun", rawText: "(25-30)% more Monster Life|Monsters cannot be Stunned", generalizedText: "^#% more monster life$|^monsters cannot be stunned$", options: {tier17: false, scary: 100}},
    {id: -199953078, regex: "d mag", rawText: "(20-30)% increased Magic Monsters", generalizedText: "^#% increased magic monsters$", options: {tier17: false, scary: 0}},
    {id: -151531429, regex: "deb", rawText: "Debuffs on Monsters expire 100% faster", generalizedText: "^debuffs on monsters expire #% faster$", options: {tier17: true, scary: 1315}},
    {id: -120302365, regex: "nim", rawText: "Area is inhabited by Animals", generalizedText: "^area is inhabited by animals$", options: {tier17: false, scary: 4}},
    {id: -50320413, regex: "uct", rawText: "+40% Monster Physical Damage Reduction", generalizedText: "^#% monster physical damage reduction$", options: {tier17: false, scary: 150}},
    {id: -36534794, regex: "cul", rawText: "Area is inhabited by Cultists of Kitava", generalizedText: "^area is inhabited by cultists of kitava$", options: {tier17: false, scary: 4}},
    {id: -34164001, regex: "vola", rawText: "Rare Monsters have Volatile Cores", generalizedText: "^rare monsters have volatile cores$", options: {tier17: true, scary: 1187}},
    {id: -6327993, regex: "o su", rawText: "Monsters have +100% chance to Suppress Spell Damage", generalizedText: "^monsters have #% chance to suppress spell damage$", options: {tier17: true, scary: 1497}},
    {id: 60084997, regex: "ss ac", rawText: "Players have 25% less Accuracy Rating", generalizedText: "^players have #% less accuracy rating$", options: {tier17: false, scary: 85}},
    {id: 164309504, regex: "s rec", rawText: "Players have 60% less Recovery Rate of Life and Energy Shield", generalizedText: "^players have #% less recovery rate of life and energy shield$", options: {tier17: false, scary: 650}},
    {id: 190130927, regex: "hock$", rawText: "All Monster Damage can Ignite, Freeze and Shock|Monsters Ignite, Freeze and Shock on Hit", generalizedText: "^all monster damage can ignite, freeze and shock$|^monsters ignite, freeze and shock on hit$", options: {tier17: true, scary: 1226}},
    {id: 200974414, regex: "lw", rawText: "All Monster Damage from Hits always Ignites", generalizedText: "^all monster damage from hits always ignites$", options: {tier17: false, scary: 99}},
    {id: 229682870, regex: "vu", rawText: "Players are Cursed with Vulnerability", generalizedText: "^players are cursed with vulnerability$", options: {tier17: false, scary: 360}},
    {id: 310013779, regex: "rke", rawText: "Players are Marked for Death for 10 seconds|after killing a Rare or Unique monster", generalizedText: "^players are marked for death for # seconds$|^after killing a rare or unique monster$", options: {tier17: true, scary: 1196}},
    {id: 355492922, regex: "fra", rawText: "25% chance for Rare Monsters to Fracture on death", generalizedText: "^#% chance for rare monsters to fracture on death$", options: {tier17: true, scary: 1227}},
    {id: 465825356, regex: "hind", rawText: "Monsters Hinder on Hit with Spells", generalizedText: "^monsters hinder on hit with spells$", options: {tier17: false, scary: 50}},
    {id: 493223943, regex: "rses", rawText: "60% less effect of Curses on Monsters", generalizedText: "^#% less effect of curses on monsters$", options: {tier17: false, scary: 363}},
    {id: 511980950, regex: "tota", rawText: "Players have (50-60)% reduced Maximum total Life, Mana and Energy Shield Recovery per second from Leech", generalizedText: "^players have #% reduced maximum total life, mana and energy shield recovery per second from leech$", options: {tier17: true, scary: 1191}},
    {id: 525934570, regex: "hil", rawText: "Area has patches of Chilled Ground", generalizedText: "^area has patches of chilled ground$", options: {tier17: false, scary: 310}},
    {id: 550943268, regex: "r at", rawText: "(25-30)% increased Monster Movement Speed|(35-45)% increased Monster Attack Speed|(35-45)% increased Monster Cast Speed", generalizedText: "^#% increased monster movement speed$|^#% increased monster attack speed$|^#% increased monster cast speed$", options: {tier17: true, scary: 607}},
    {id: 577057701, regex: "kes", rawText: "Monsters take (35-45)% reduced Extra Damage from Critical Strikes", generalizedText: "^monsters take #% reduced extra damage from critical strikes$", options: {tier17: true, scary: 1452}},
    {id: 581472251, regex: "tun", rawText: "Monsters cannot be Stunned|Monsters' Action Speed cannot be modified to below Base Value|Monsters' Movement Speed cannot be modified to below Base Value", generalizedText: "^monsters cannot be stunned$|^monsters' action speed cannot be modified to below base value$|^monsters' movement speed cannot be modified to below base value$", options: {tier17: true, scary: 1315}},
    {id: 638612957, regex: "prev", rawText: "Monsters have 50% increased Accuracy Rating|Players have -20% to amount of Suppressed Spell Damage Prevented", generalizedText: "^monsters have #% increased accuracy rating$|^players have -#% to amount of suppressed spell damage prevented$", options: {tier17: false, scary: 365}},
    {id: 667938191, regex: "^buf", rawText: "Buffs on Players expire 70% faster", generalizedText: "^buffs on players expire #% faster$", options: {tier17: false, scary: 96}},
    {id: 694566327, regex: "r li", rawText: "(40-49)% more Monster Life", generalizedText: "^#% more monster life$", options: {tier17: false, scary: 100}},
    {id: 719111530, regex: "a pow", rawText: "Monsters gain a Power Charge on Hit", generalizedText: "^monsters gain a power charge on hit$", options: {tier17: false, scary: 80}},
    {id: 729842044, regex: "coo", rawText: "Players have 40% less Cooldown Recovery Rate", generalizedText: "^players have #% less cooldown recovery rate$", options: {tier17: false, scary: 94}},
    {id: 755773474, regex: "o ig", rawText: "Monsters have a 20% chance to Ignite, Freeze and Shock on Hit", generalizedText: "^monsters have a #% chance to ignite, freeze and shock on hit$", options: {tier17: false, scary: 98}},
    {id: 762130113, regex: "ment$", rawText: "Monsters gain (180-200)% of their Physical Damage as Extra Damage of a random Element", generalizedText: "^monsters gain #% of their physical damage as extra damage of a random element$", options: {tier17: true, scary: 1664}},
    {id: 793356205, regex: "f de", rawText: "Area has patches of desecrated ground", generalizedText: "^area has patches of desecrated ground$", options: {tier17: false, scary: 310}},
    {id: 856370140, regex: "wni", rawText: "Area contains Drowning Orbs", generalizedText: "^area contains drowning orbs$", options: {tier17: true, scary: 1225}},
    {id: 884783454, regex: "aim", rawText: "Monsters Maim on Hit with Attacks", generalizedText: "^monsters maim on hit with attacks$", options: {tier17: false, scary: 50}},
    {id: 894187505, regex: "o al", rawText: "Players have (-12--9)% to all maximum Resistances", generalizedText: "^players have -#% to all maximum resistances$", options: {tier17: false, scary: 980}},
    {id: 920184875, regex: "k at", rawText: "Monsters have +50% Chance to Block Attack Damage", generalizedText: "^monsters have #% chance to block attack damage$", options: {tier17: true, scary: 1304}},
    {id: 927184680, regex: "iers$", rawText: "(35-45)% increased number of Rare Monsters|Rare Monsters each have 2 additional Modifiers", generalizedText: "^#% increased number of rare monsters$|^rare monsters each have # additional modifiers$", options: {tier17: true, scary: 1232}},
    {id: 931213255, regex: "oa", rawText: "Area is inhabited by Goatmen", generalizedText: "^area is inhabited by goatmen$", options: {tier17: false, scary: 4}},
    {id: 1006692915, regex: "ered", rawText: "Monsters gain (31-35)% of their Physical Damage as Extra Chaos Damage|Monsters Inflict Withered for 2 seconds on Hit", generalizedText: "^monsters gain #% of their physical damage as extra chaos damage$|^monsters inflict withered for # seconds on hit$", options: {tier17: false, scary: 455}},
    {id: 1088280684, regex: "e charge o", rawText: "Monsters gain an Endurance Charge on Hit", generalizedText: "^monsters gain an endurance charge on hit$", options: {tier17: false, scary: 80}},
    {id: 1141186022, regex: "son$", rawText: "Monsters Poison on Hit|All Damage from Monsters' Hits can Poison|Monsters have 100% increased Poison Duration", generalizedText: "^monsters poison on hit$|^all damage from monsters' hits can poison$|^monsters have #% increased poison duration$", options: {tier17: true, scary: 1606}},
    {id: 1177412437, regex: "fif", rawText: "Monsters' Attacks Impale on Hit When a fifth Impale is inflicted on a Player, Impales are removed to Reflect their Physical Damage multiplied by their remaining Hits to that Player and their Allies within 1.8 metres", generalizedText: "^monsters' attacks impale on hit when a fifth impale is inflicted on a player, impales are removed to reflect their physical damage multiplied by their remaining hits to that player and their allies within #.# metres$", options: {tier17: true, scary: 1201}},
    {id: 1197254574, regex: "kn", rawText: "Players are Cursed with Elemental Weakness", generalizedText: "^players are cursed with elemental weakness$", options: {tier17: false, scary: 360}},
    {id: 1204424670, regex: "mmo", rawText: "15% of Damage Players' Totems take from Hits is taken from their Summoner's Life instead", generalizedText: "^#% of damage players' totems take from hits is taken from their summoner's life instead$", options: {tier17: true, scary: 1237}},
    {id: 1245770343, regex: "lun", rawText: "Area is inhabited by Lunaris fanatics", generalizedText: "^area is inhabited by lunaris fanatics$", options: {tier17: false, scary: 4}},
    {id: 1261702218, regex: "mpo", rawText: "Players are Cursed with Temporal Chains", generalizedText: "^players are cursed with temporal chains$", options: {tier17: false, scary: 360}},
    {id: 1270766659, regex: "^buf", rawText: "Buffs on Players expire 100% faster", generalizedText: "^buffs on players expire #% faster$", options: {tier17: true, scary: 1316}},
    {id: 1275003885, regex: "r el", rawText: "+25% Monster Chaos Resistance|+40% Monster Elemental Resistances", generalizedText: "^#% monster chaos resistance$|^#% monster elemental resistances$", options: {tier17: false, scary: 100}},
    {id: 1303025587, regex: "lm", rawText: "Monsters have 70% chance to Avoid Elemental Ailments", generalizedText: "^monsters have #% chance to avoid elemental ailments$", options: {tier17: false, scary: 150}},
    {id: 1320742524, regex: "emons", rawText: "Area is inhabited by Demons", generalizedText: "^area is inhabited by demons$", options: {tier17: false, scary: 4}},
    {id: 1349832036, regex: "yn", rawText: "Map Boss is accompanied by a Synthesis Boss", generalizedText: "^map boss is accompanied by a synthesis boss$", options: {tier17: true, scary: 1218}},
    {id: 1409464152, regex: "osts", rawText: "Area is inhabited by Ghosts", generalizedText: "^area is inhabited by ghosts$", options: {tier17: false, scary: 4}},
    {id: 1424932226, regex: "tiles$", rawText: "Monsters fire 2 additional Projectiles", generalizedText: "^monsters fire # additional projectiles$", options: {tier17: false, scary: 440}},
    {id: 1434328288, regex: "e \\d+% increased ar", rawText: "Monsters have 100% increased Area of Effect", generalizedText: "^monsters have #% increased area of effect$", options: {tier17: false, scary: 400}},
    {id: 1449891900, regex: "hex", rawText: "Monsters are Hexproof", generalizedText: "^monsters are hexproof$", options: {tier17: false, scary: 150}},
    {id: 1471007742, regex: "rang", rawText: "Area is inhabited by ranged monsters", generalizedText: "^area is inhabited by ranged monsters$", options: {tier17: false, scary: 4}},
    {id: 1481746664, regex: "two", rawText: "Area contains two Unique Bosses", generalizedText: "^area contains two unique bosses$", options: {tier17: false, scary: 90}},
    {id: 1514873663, regex: "non", rawText: "Players have 60% reduced effect of Non-Curse Auras from Skills", generalizedText: "^players have #% reduced effect of non-curse auras from skills$", options: {tier17: false, scary: 990}},
    {id: 1515846394, regex: "urn", rawText: "Area has patches of Burning Ground", generalizedText: "^area has patches of burning ground$", options: {tier17: false, scary: 310}},
    {id: 1523693331, regex: "ss are", rawText: "Players have (25-30)% less Area of Effect", generalizedText: "^players have #% less area of effect$", options: {tier17: true, scary: 1253}},
    {id: 1537433645, regex: "lier", rawText: "Monsters have (650-700)% increased Critical Strike Chance|+(70-75)% to Monster Critical Strike Multiplier", generalizedText: "^monsters have #% increased critical strike chance$|^+#% to monster critical strike multiplier$", options: {tier17: true, scary: 1711}},
    {id: 1546387363, regex: "tn", rawText: "Monsters deal (90-110)% extra Physical Damage as Lightning", generalizedText: "^monsters deal #% extra physical damage as lightning$", options: {tier17: false, scary: 450}},
    {id: 1594594160, regex: "ever", rawText: "Players and their Minions deal no damage for 3 out of every 10 seconds", generalizedText: "^players and their minions deal no damage for # out of every # seconds$", options: {tier17: true, scary: 1198}},
    {id: 1652154666, regex: "o su", rawText: "Monsters have +60% chance to Suppress Spell Damage", generalizedText: "^monsters have #% chance to suppress spell damage$", options: {tier17: false, scary: 290}},
    {id: 1653061490, regex: "ff$", rawText: "Unique Monsters have a random Shrine Buff", generalizedText: "^unique monsters have a random shrine buff$", options: {tier17: true, scary: 1278}},
    {id: 1687576035, regex: "son o", rawText: "Monsters Poison on Hit", generalizedText: "^monsters poison on hit$", options: {tier17: false, scary: 390}},
    {id: 1691639998, regex: "d li", rawText: "Unique Boss has 35% increased Life|Unique Boss has 70% increased Area of Effect", generalizedText: "^unique boss has #% increased life$|^unique boss has #% increased area of effect$", options: {tier17: false, scary: 400}},
    {id: 1734526203, regex: "f ph", rawText: "Monsters reflect 18% of Physical Damage", generalizedText: "^monsters reflect #% of physical damage$", options: {tier17: false, scary: 1000}},
    {id: 1741763812, regex: "ocke", rawText: "Area has patches of Shocked Ground which increase Damage taken by 50%", generalizedText: "^area has patches of shocked ground which increase damage taken by #%$", options: {tier17: false, scary: 310}},
    {id: 1769059225, regex: "m end", rawText: "Monsters have +1 to Maximum Endurance Charges|Monsters gain an Endurance Charge when hit", generalizedText: "^monsters have # to maximum endurance charges$|^monsters gain an endurance charge when hit$", options: {tier17: true, scary: 1210}},
    {id: 1809097991, regex: "xpo", rawText: "Players cannot inflict Exposure", generalizedText: "^players cannot inflict exposure$", options: {tier17: false, scary: 150}},
    {id: 1815471633, regex: "ndea", rawText: "Area is inhabited by Undead", generalizedText: "^area is inhabited by undead$", options: {tier17: false, scary: 4}},
    {id: 1822260921, regex: "on,", rawText: "Monsters have a 50% chance to avoid Poison, Impale, and Bleeding", generalizedText: "^monsters have a #% chance to avoid poison, impale, and bleeding$", options: {tier17: false, scary: 390}},
    {id: 1855646823, regex: "nu", rawText: "(20-30)% increased number of Rare Monsters", generalizedText: "^#% increased number of rare monsters$", options: {tier17: false, scary: 1}},
    {id: 1909893191, regex: "d fl", rawText: "Players gain 50% reduced Flask Charges", generalizedText: "^players gain #% reduced flask charges$", options: {tier17: false, scary: 210}},
    {id: 1916229172, regex: "pin", rawText: "Monsters inflict 2 Grasping Vines on Hit", generalizedText: "^monsters inflict # grasping vines on hit$", options: {tier17: true, scary: 1204}},
    {id: 1936863142, regex: "ss are", rawText: "Players have 25% less Area of Effect", generalizedText: "^players have #% less area of effect$", options: {tier17: false, scary: 60}},
    {id: 1965894066, regex: "ske", rawText: "Area is inhabited by Skeletons", generalizedText: "^area is inhabited by skeletons$", options: {tier17: false, scary: 4}},
    {id: 2023345436, regex: "itc", rawText: "Area is inhabited by Sea Witches and their Spawn", generalizedText: "^area is inhabited by sea witches and their spawn$", options: {tier17: false, scary: 4}},
    {id: 2042799595, regex: "ety", rawText: "Area has increased monster variety", generalizedText: "^area has increased monster variety$", options: {tier17: false, scary: 8}},
    {id: 2043204851, regex: "d at", rawText: "Unique Boss deals 25% increased Damage|Unique Boss has 30% increased Attack and Cast Speed", generalizedText: "^unique boss deals #% increased damage$|^unique boss has #% increased attack and cast speed$", options: {tier17: false, scary: 420}},
    {id: 2075940345, regex: "wb", rawText: "Players are assaulted by Bloodstained Sawblades", generalizedText: "^players are assaulted by bloodstained sawblades$", options: {tier17: true, scary: 1195}},
    {id: 2128933960, regex: "' mi", rawText: "Players' Minions have 50% less Attack Speed|Players' Minions have 50% less Cast Speed|Players' Minions have 50% less Movement Speed", generalizedText: "^players' minions have #% less attack speed$|^players' minions have #% less cast speed$|^players' minions have #% less movement speed$", options: {tier17: true, scary: 1608}},
  ],
  optimizationTable: {
    "-2106392014:-1129414698:-926892006:-474049160:511980950:894187505:1769059225": {ids: [-2106392014, -1129414698, -926892006, -474049160, 511980950, 894187505, 1769059225], regex: "max", weight: 3, count: 7},
    "-2106392014:-1636388574:-620427144:894187505:1275003885:1349832036": {ids: [-2106392014, -1636388574, -620427144, 894187505, 1275003885, 1349832036], regex: "sis", weight: 3, count: 6},
    "-2106392014:-1636388574:-620427144:894187505:1349832036": {ids: [-2106392014, -1636388574, -620427144, 894187505, 1349832036], regex: "sis", weight: 3, count: 5},
    "-2106392014:-1505174556:-1129414698:-474049160:511980950:894187505:1769059225": {ids: [-2106392014, -1505174556, -1129414698, -474049160, 511980950, 894187505, 1769059225], regex: "max", weight: 3, count: 7},
    "-2106392014:-1636388574:-620427144:1275003885:1349832036": {ids: [-2106392014, -1636388574, -620427144, 1275003885, 1349832036], regex: "sis", weight: 3, count: 5},
    "-2106392014:-1505174556:-1129414698:-926892006:-474049160:511980950:1769059225": {ids: [-2106392014, -1505174556, -1129414698, -926892006, -474049160, 511980950, 1769059225], regex: "max", weight: 3, count: 7},
    "-2106392014:-1636388574:-620427144:894187505:1275003885": {ids: [-2106392014, -1636388574, -620427144, 894187505, 1275003885], regex: "sist", weight: 4, count: 5},
    "-2106392014:-1636388574:-620427144:894187505": {ids: [-2106392014, -1636388574, -620427144, 894187505], regex: "sist", weight: 4, count: 4},
    "-2106392014:-1636388574:-620427144:1275003885": {ids: [-2106392014, -1636388574, -620427144, 1275003885], regex: "sist", weight: 4, count: 4},
    "-2106392014:190130927:200974414:894187505": {ids: [-2106392014, 190130927, 200974414, 894187505], regex: "all m", weight: 7, count: 4},
    "-2106392014:190130927:200974414": {ids: [-2106392014, 190130927, 200974414], regex: "all m", weight: 7, count: 3},
    "-2106392014:638612957:894187505": {ids: [-2106392014, 638612957, 894187505], regex: "% to a", weight: 8, count: 3},
    "-2106392014:638612957": {ids: [-2106392014, 638612957], regex: "% to a", weight: 8, count: 2},
    "-2103899765:-1462808973:577057701": {ids: [-2103899765, -1462808973, 577057701], regex: "ik", weight: 2, count: 3},
    "-2103899765:-1462808973:1537433645": {ids: [-2103899765, -1462808973, 1537433645], regex: "ik", weight: 2, count: 3},
    "-2103899765:577057701:1537433645": {ids: [-2103899765, 577057701, 1537433645], regex: "ik", weight: 2, count: 3},
    "-2103899765:577057701:1204424670:1741763812": {ids: [-2103899765, 577057701, 1204424670, 1741763812], regex: "tak", weight: 3, count: 4},
    "-2103899765:1204424670:1741763812": {ids: [-2103899765, 1204424670, 1741763812], regex: "tak", weight: 3, count: 3},
    "-2103899765:1204424670": {ids: [-2103899765, 1204424670], regex: "s ta", weight: 6, count: 2},
    "-2103899765:762130113": {ids: [-2103899765, 762130113], regex: "ra d", weight: 6, count: 2},
    "-2103899765:577057701:1204424670": {ids: [-2103899765, 577057701, 1204424670], regex: "s ta", weight: 6, count: 3},
    "-2103899765:577057701:762130113": {ids: [-2103899765, 577057701, 762130113], regex: "ra d", weight: 6, count: 3},
    "-2103899765:577057701:1514873663": {ids: [-2103899765, 577057701, 1514873663], regex: "ced e", weight: 7, count: 3},
    "-2103899765:1514873663": {ids: [-2103899765, 1514873663], regex: "ced e", weight: 7, count: 2},
    "-2103899765:200974414:1141186022:1594594160": {ids: [-2103899765, 200974414, 1141186022, 1594594160], regex: "mage f", weight: 8, count: 4},
    "-2103899765:200974414:1141186022:1204424670": {ids: [-2103899765, 200974414, 1141186022, 1204424670], regex: "e from", weight: 8, count: 4},
    "-2103899765:200974414:577057701:1141186022:1594594160": {ids: [-2103899765, 200974414, 577057701, 1141186022, 1594594160], regex: "mage f", weight: 8, count: 5},
    "-2103899765:200974414:577057701:1141186022:1204424670": {ids: [-2103899765, 200974414, 577057701, 1141186022, 1204424670], regex: "e from", weight: 8, count: 5},
    "-2103899765:355492922": {ids: [-2103899765, 355492922], regex: "sters t", weight: 9, count: 2},
    "-2103899765:200974414:1141186022": {ids: [-2103899765, 200974414, 1141186022], regex: "mage fr", weight: 9, count: 3},
    "-2103899765:200974414:577057701:1141186022": {ids: [-2103899765, 200974414, 577057701, 1141186022], regex: "mage fr", weight: 9, count: 4},
    "-2103899765:355492922:577057701": {ids: [-2103899765, 355492922, 577057701], regex: "sters t", weight: 9, count: 3},
    "-2103899765:-2068532865:-1403235912:511980950:577057701:1514873663": {ids: [-2103899765, -2068532865, -1403235912, 511980950, 577057701, 1514873663], regex: "e \\d+% r", weight: 11, count: 6},
    "-2103899765:-2068532865:-1403235912:511980950:1514873663": {ids: [-2103899765, -2068532865, -1403235912, 511980950, 1514873663], regex: "e \\d+% r", weight: 11, count: 5},
    "-2068532865:-1660701657:-1331734856:-1033371824:1514873663:1909893191:1965894066": {ids: [-2068532865, -1660701657, -1331734856, -1033371824, 1514873663, 1909893191, 1965894066], regex: "sk", weight: 2, count: 7},
    "-2068532865:-1331734856:-1033371824:-500015770:1514873663": {ids: [-2068532865, -1331734856, -1033371824, -500015770, 1514873663], regex: "ski", weight: 3, count: 5},
    "-2068532865:-1331734856:-1033371824:-500015770:310013779:1514873663": {ids: [-2068532865, -1331734856, -1033371824, -500015770, 310013779, 1514873663], regex: "kil", weight: 3, count: 6},
    "-2068532865:-1331734856:-500015770": {ids: [-2068532865, -1331734856, -500015770], regex: "tim", weight: 3, count: 3},
    "-2068532865:-1636388574:-1331734856:-894195682:-529767307:581472251:1424932226": {ids: [-2068532865, -1636388574, -1331734856, -894195682, -529767307, 581472251, 1424932226], regex: "cti", weight: 3, count: 7},
    "-2068532865:-1636388574:-1331734856:-894195682:-529767307:-50320413:581472251": {ids: [-2068532865, -1636388574, -1331734856, -894195682, -529767307, -50320413, 581472251], regex: "cti", weight: 3, count: 7},
    "-2068532865:-1331734856": {ids: [-2068532865, -1331734856], regex: "tim", weight: 3, count: 2},
    "-2068532865:-1331734856:-1033371824:1514873663": {ids: [-2068532865, -1331734856, -1033371824, 1514873663], regex: "ski", weight: 3, count: 4},
    "-2068532865:-1331734856:-1033371824:310013779:1514873663": {ids: [-2068532865, -1331734856, -1033371824, 310013779, 1514873663], regex: "kil", weight: 3, count: 5},
    "-2068532865:-1660701657": {ids: [-2068532865, -1660701657], regex: "hey", weight: 3, count: 2},
    "-2068532865:-1636388574:-529767307:-50320413:581472251": {ids: [-2068532865, -1636388574, -529767307, -50320413, 581472251], regex: "ctio", weight: 4, count: 5},
    "-2068532865:-529767307:581472251": {ids: [-2068532865, -529767307, 581472251], regex: "acti", weight: 4, count: 3},
    "-2068532865:-1636388574:-529767307:581472251": {ids: [-2068532865, -1636388574, -529767307, 581472251], regex: "ctio", weight: 4, count: 4},
    "-2068532865:638612957": {ids: [-2068532865, 638612957], regex: "d ac", weight: 6, count: 2},
    "-2068532865:-1033371824": {ids: [-2068532865, -1033371824], regex: "ch t", weight: 6, count: 2},
    "-2068532865:-529767307:-380667604:581472251": {ids: [-2068532865, -529767307, -380667604, 581472251], regex: "ion s", weight: 7, count: 4},
    "-2068532865:-1403235912:511980950:577057701:1514873663": {ids: [-2068532865, -1403235912, 511980950, 577057701, 1514873663], regex: "e \\d+% r", weight: 11, count: 5},
    "-2068532865:-1403235912:511980950:1514873663": {ids: [-2068532865, -1403235912, 511980950, 1514873663], regex: "ve \\d+% r", weight: 12, count: 4},
    "-2066574800:-926892006:762130113:1006692915": {ids: [-2066574800, -926892006, 762130113, 1006692915], regex: "as e", weight: 6, count: 4},
    "-2066574800:-926892006:-151531429:667938191:762130113:1006692915:1270766659": {ids: [-2066574800, -926892006, -151531429, 667938191, 762130113, 1006692915, 1270766659], regex: "s ex", weight: 6, count: 7},
    "-2066574800:-1636388574:-50320413:762130113:1006692915:1177412437": {ids: [-2066574800, -1636388574, -50320413, 762130113, 1006692915, 1177412437], regex: "r ph", weight: 6, count: 6},
    "-2066574800:-1505174556:-926892006:-151531429:667938191:762130113:1006692915": {ids: [-2066574800, -1505174556, -926892006, -151531429, 667938191, 762130113, 1006692915], regex: "s ex", weight: 6, count: 7},
    "-2066574800:-1636388574:762130113:1006692915:1177412437": {ids: [-2066574800, -1636388574, 762130113, 1006692915, 1177412437], regex: "r ph", weight: 6, count: 5},
    "-2066574800:-1505174556:-926892006:-151531429:762130113:1006692915:1270766659": {ids: [-2066574800, -1505174556, -926892006, -151531429, 762130113, 1006692915, 1270766659], regex: "s ex", weight: 6, count: 7},
    "-2066574800:762130113:1006692915:1177412437": {ids: [-2066574800, 762130113, 1006692915, 1177412437], regex: "ir p", weight: 6, count: 4},
    "-2066574800:-1505174556:-926892006:762130113:1006692915": {ids: [-2066574800, -1505174556, -926892006, 762130113, 1006692915], regex: "as e", weight: 6, count: 5},
    "-2066574800:-1505174556:762130113:1006692915": {ids: [-2066574800, -1505174556, 762130113, 1006692915], regex: "as e", weight: 6, count: 4},
    "-2066574800:-1505174556:-151531429:667938191:762130113:1006692915:1270766659": {ids: [-2066574800, -1505174556, -151531429, 667938191, 762130113, 1006692915, 1270766659], regex: "s ex", weight: 6, count: 7},
    "-2066574800:1006692915": {ids: [-2066574800, 1006692915], regex: "ra c", weight: 6, count: 2},
    "-2066574800:-1121411477:-1121327683:762130113:1006692915:1546387363": {ids: [-2066574800, -1121411477, -1121327683, 762130113, 1006692915, 1546387363], regex: "ge as", weight: 7, count: 6},
    "-2066574800:762130113:1006692915": {ids: [-2066574800, 762130113, 1006692915], regex: "% of t", weight: 8, count: 3},
    "-2066574800:-926892006:762130113:1006692915:1909893191": {ids: [-2066574800, -926892006, 762130113, 1006692915, 1909893191], regex: "in \\d+%", weight: 10, count: 5},
    "-2066574800:-1331734856:-926892006:-500015770:762130113:1006692915:1909893191": {ids: [-2066574800, -1331734856, -926892006, -500015770, 762130113, 1006692915, 1909893191], regex: "ain \\d+", weight: 10, count: 7},
    "-2066574800:-1505174556:-926892006:762130113:1006692915:1909893191": {ids: [-2066574800, -1505174556, -926892006, 762130113, 1006692915, 1909893191], regex: "in \\d+%", weight: 10, count: 6},
    "-2066574800:-1505174556:762130113:1006692915:1909893191": {ids: [-2066574800, -1505174556, 762130113, 1006692915, 1909893191], regex: "in \\d+%", weight: 10, count: 5},
    "-2066574800:-1505174556:-1331734856:-500015770:762130113:1006692915:1909893191": {ids: [-2066574800, -1505174556, -1331734856, -500015770, 762130113, 1006692915, 1909893191], regex: "ain \\d+", weight: 10, count: 7},
    "-2066574800:-1505174556:-1331734856:-926892006:762130113:1006692915:1909893191": {ids: [-2066574800, -1505174556, -1331734856, -926892006, 762130113, 1006692915, 1909893191], regex: "ain \\d+", weight: 10, count: 7},
    "-2060936381:-2060936381:1809097991": {ids: [-2060936381, -2060936381, 1809097991], regex: "pos", weight: 3, count: 3},
    "-2060936381:1809097991": {ids: [-2060936381, 1809097991], regex: "pos", weight: 3, count: 2},
    "-2060936381:-2060936381:638612957": {ids: [-2060936381, -2060936381, 638612957], regex: "ssed", weight: 4, count: 3},
    "-2060936381:638612957": {ids: [-2060936381, 638612957], regex: "ssed", weight: 4, count: 2},
    "-2060936381:-2060936381:1481746664": {ids: [-2060936381, -2060936381, 1481746664], regex: "bosse", weight: 5, count: 3},
    "-2060936381:1481746664": {ids: [-2060936381, 1481746664], regex: "bosse", weight: 5, count: 2},
    "-2060936381:-2060936381:1481746664:1691639998:2043204851": {ids: [-2060936381, -2060936381, 1481746664, 1691639998, 2043204851], regex: "ue b", weight: 6, count: 5},
    "-2060936381:1481746664:1691639998:2043204851": {ids: [-2060936381, 1481746664, 1691639998, 2043204851], regex: "ue b", weight: 6, count: 4},
    "-2060936381:1177412437": {ids: [-2060936381, 1177412437], regex: "es are", weight: 8, count: 2},
    "-2060936381:-2060936381:1177412437": {ids: [-2060936381, -2060936381, 1177412437], regex: "es are", weight: 8, count: 3},
    "-1994088653:-1942271057:-199953078:550943268:2042799595": {ids: [-1994088653, -1942271057, -199953078, 550943268, 2042799595], regex: "ased m", weight: 8, count: 5},
    "-1994088653:-1942271057:-620427144:190130927:200974414": {ids: [-1994088653, -1942271057, -620427144, 190130927, 200974414], regex: "ster d", weight: 8, count: 5},
    "-1994088653:-1942271057:-199953078:550943268:550943268:2042799595": {ids: [-1994088653, -1942271057, -199953078, 550943268, 550943268, 2042799595], regex: "ased m", weight: 8, count: 6},
    "-1994088653:-620427144:190130927:200974414": {ids: [-1994088653, -620427144, 190130927, 200974414], regex: "ster d", weight: 8, count: 4},
    "-1994088653:-199953078:550943268:550943268:2042799595": {ids: [-1994088653, -199953078, 550943268, 550943268, 2042799595], regex: "ased m", weight: 8, count: 5},
    "-1994088653:-1942271057:550943268:2042799595": {ids: [-1994088653, -1942271057, 550943268, 2042799595], regex: "ased mo", weight: 9, count: 4},
    "-1994088653:-1942271057:550943268:550943268:2042799595": {ids: [-1994088653, -1942271057, 550943268, 550943268, 2042799595], regex: "ased mo", weight: 9, count: 5},
    "-1994088653:550943268:550943268:2042799595": {ids: [-1994088653, 550943268, 550943268, 2042799595], regex: "ased mo", weight: 9, count: 4},
    "-1994088653:-1942271057:-199953078:550943268": {ids: [-1994088653, -1942271057, -199953078, 550943268], regex: "% increased m", weight: 15, count: 4},
    "-1994088653:-1942271057:-199953078:550943268:550943268": {ids: [-1994088653, -1942271057, -199953078, 550943268, 550943268], regex: "% increased m", weight: 15, count: 5},
    "-1994088653:-199953078:550943268:550943268": {ids: [-1994088653, -199953078, 550943268, 550943268], regex: "% increased m", weight: 15, count: 4},
    "-1952553467:550943268:884783454:920184875:1177412437:2043204851:2128933960": {ids: [-1952553467, 550943268, 884783454, 920184875, 1177412437, 2043204851, 2128933960], regex: "att", weight: 3, count: 7},
    "-1952553467:1177412437:1822260921": {ids: [-1952553467, 1177412437, 1822260921], regex: "imp", weight: 3, count: 3},
    "-1952553467:884783454:1177412437": {ids: [-1952553467, 884783454, 1177412437], regex: "acks", weight: 4, count: 3},
    "-1952553467:-1331734856:-529767307:-500015770:581472251:1141186022:1177412437": {ids: [-1952553467, -1331734856, -529767307, -500015770, 581472251, 1141186022, 1177412437], regex: "ters'", weight: 5, count: 7},
    "-1952553467:-529767307:581472251:1177412437": {ids: [-1952553467, -529767307, 581472251, 1177412437], regex: "' a", weight: 5, count: 4},
    "-1952553467:-1331734856:-529767307:581472251:1141186022:1177412437": {ids: [-1952553467, -1331734856, -529767307, 581472251, 1141186022, 1177412437], regex: "ters'", weight: 5, count: 6},
    "-1952553467:-1403235912:755773474:920184875:1303025587:1652154666:1822260921": {ids: [-1952553467, -1403235912, 755773474, 920184875, 1303025587, 1652154666, 1822260921], regex: "ce t", weight: 6, count: 7},
    "-1952553467:1177412437": {ids: [-1952553467, 1177412437], regex: "' at", weight: 6, count: 2},
    "-1952553467:-1403235912:-6327993:755773474:920184875:1303025587:1822260921": {ids: [-1952553467, -1403235912, -6327993, 755773474, 920184875, 1303025587, 1822260921], regex: "ce t", weight: 6, count: 7},
    "-1952553467:755773474": {ids: [-1952553467, 755773474], regex: "e to i", weight: 8, count: 2},
    "-1952553467:-1129414698:-1036254613:-474049160:719111530:1088280684:1177412437": {ids: [-1952553467, -1129414698, -1036254613, -474049160, 719111530, 1088280684, 1177412437], regex: "e on h", weight: 8, count: 7},
    "-1952553467:-1129414698:-474049160:719111530:1088280684:1177412437": {ids: [-1952553467, -1129414698, -474049160, 719111530, 1088280684, 1177412437], regex: "e on h", weight: 8, count: 6},
    "-1952553467:-1129414698:-1036254613:-474049160:1088280684:1177412437": {ids: [-1952553467, -1129414698, -1036254613, -474049160, 1088280684, 1177412437], regex: "e on h", weight: 8, count: 6},
    "-1952553467:-6327993:755773474:920184875:1303025587:1652154666:1822260921": {ids: [-1952553467, -6327993, 755773474, 920184875, 1303025587, 1652154666, 1822260921], regex: "% chance t", weight: 12, count: 7},
    "-1952553467:755773474:920184875:1303025587:1652154666:1822260921": {ids: [-1952553467, 755773474, 920184875, 1303025587, 1652154666, 1822260921], regex: "% chance t", weight: 12, count: 6},
    "-1952553467:-6327993:755773474:920184875:1303025587:1822260921": {ids: [-1952553467, -6327993, 755773474, 920184875, 1303025587, 1822260921], regex: "% chance t", weight: 12, count: 6},
    "-1942271057:-620427144:190130927:200974414": {ids: [-1942271057, -620427144, 190130927, 200974414], regex: "ster d", weight: 8, count: 4},
    "-1942271057:-199953078:550943268:550943268:2042799595": {ids: [-1942271057, -199953078, 550943268, 550943268, 2042799595], regex: "ased m", weight: 8, count: 5},
    "-1942271057:550943268:550943268:2042799595": {ids: [-1942271057, 550943268, 550943268, 2042799595], regex: "ased mo", weight: 9, count: 4},
    "-1942271057:-199953078:550943268:550943268": {ids: [-1942271057, -199953078, 550943268, 550943268], regex: "% increased m", weight: 15, count: 4},
    "-1926268187:511980950:1204424670": {ids: [-1926268187, 511980950, 1204424670], regex: "tot", weight: 3, count: 3},
    "-1926268187:1204424670": {ids: [-1926268187, 1204424670], regex: "tote", weight: 4, count: 2},
    "-1869263008:-1036254613:-474049160": {ids: [-1869263008, -1036254613, -474049160], regex: "nz", weight: 2, count: 3},
    "-1869263008:-474049160": {ids: [-1869263008, -474049160], regex: "nz", weight: 2, count: 2},
    "-1869263008:-1036254613:-474049160:190130927:755773474": {ids: [-1869263008, -1036254613, -474049160, 190130927, 755773474], regex: "fre", weight: 3, count: 5},
    "-1869263008:-1129414698:719111530": {ids: [-1869263008, -1129414698, 719111530], regex: "pow", weight: 3, count: 3},
    "-1869263008:1088280684:1769059225": {ids: [-1869263008, 1088280684, 1769059225], regex: "ndu", weight: 3, count: 3},
    "-1869263008:-1321541749:1088280684:1769059225": {ids: [-1869263008, -1321541749, 1088280684, 1769059225], regex: "end", weight: 3, count: 4},
    "-1869263008:1177412437": {ids: [-1869263008, 1177412437], regex: "er,", weight: 3, count: 2},
    "-1869263008:-474049160:190130927:755773474": {ids: [-1869263008, -474049160, 190130927, 755773474], regex: "fre", weight: 3, count: 4},
    "-1869263008:-1129414698": {ids: [-1869263008, -1129414698], regex: "pow", weight: 3, count: 2},
    "-1869263008:-1129414698:-474049160:1769059225:1909893191": {ids: [-1869263008, -1129414698, -474049160, 1769059225, 1909893191], regex: "rges", weight: 4, count: 5},
    "-1869263008:1204424670": {ids: [-1869263008, 1204424670], regex: "stea", weight: 4, count: 2},
    "-1869263008:-1129414698:-474049160:719111530:1088280684:1769059225:1909893191": {ids: [-1869263008, -1129414698, -474049160, 719111530, 1088280684, 1769059225, 1909893191], regex: "harg", weight: 4, count: 7},
    "-1869263008:-1129414698:-1036254613:-474049160:1088280684:1769059225:1909893191": {ids: [-1869263008, -1129414698, -1036254613, -474049160, 1088280684, 1769059225, 1909893191], regex: "harg", weight: 4, count: 7},
    "-1869263008:190130927:755773474": {ids: [-1869263008, 190130927, 755773474], regex: ", f", weight: 5, count: 3},
    "-1869263008:-525989649:164309504:511980950": {ids: [-1869263008, -525989649, 164309504, 511980950], regex: "d en", weight: 6, count: 4},
    "-1869263008:-525989649:-151531429:493223943:667938191:1006692915:1916229172": {ids: [-1869263008, -525989649, -151531429, 493223943, 667938191, 1006692915, 1916229172], regex: "s on", weight: 6, count: 7},
    "-1869263008:-525989649:-151531429:493223943:1006692915:1270766659:1916229172": {ids: [-1869263008, -525989649, -151531429, 493223943, 1006692915, 1270766659, 1916229172], regex: "s on", weight: 6, count: 7},
    "-1869263008:493223943:1916229172": {ids: [-1869263008, 493223943, 1916229172], regex: "es on", weight: 7, count: 3},
    "-1869263008:-525989649:1006692915:1916229172": {ids: [-1869263008, -525989649, 1006692915, 1916229172], regex: "s on h", weight: 8, count: 4},
    "-1869263008:1916229172": {ids: [-1869263008, 1916229172], regex: "es on h", weight: 9, count: 2},
    "-1869263008:1769059225": {ids: [-1869263008, 1769059225], regex: "e charges", weight: 11, count: 2},
    "-1842810065:-1621229119": {ids: [-1842810065, -1621229119], regex: "ched", weight: 4, count: 2},
    "-1842810065:-208379648:-34164001:355492922:927184680:1855646823": {ids: [-1842810065, -208379648, -34164001, 355492922, 927184680, 1855646823], regex: "re mon", weight: 8, count: 6},
    "-1842810065:-208379648:-34164001:355492922:694566327:927184680:1855646823": {ids: [-1842810065, -208379648, -34164001, 355492922, 694566327, 927184680, 1855646823], regex: "re mon", weight: 8, count: 7},
    "-1842810065:-34164001:355492922:927184680:1855646823": {ids: [-1842810065, -34164001, 355492922, 927184680, 1855646823], regex: "rare m", weight: 8, count: 5},
    "-1842810065:-34164001:355492922:927184680": {ids: [-1842810065, -34164001, 355492922, 927184680], regex: "rare m", weight: 8, count: 4},
    "-1842810065:-704095924:-208379648:-34164001:355492922:694566327:927184680": {ids: [-1842810065, -704095924, -208379648, -34164001, 355492922, 694566327, 927184680], regex: "re mon", weight: 8, count: 7},
    "-1842810065:-704095924:-208379648:-34164001:355492922:927184680:1855646823": {ids: [-1842810065, -704095924, -208379648, -34164001, 355492922, 927184680, 1855646823], regex: "re mon", weight: 8, count: 7},
    "-1842810065:190130927:1006692915:1916229172": {ids: [-1842810065, 190130927, 1006692915, 1916229172], regex: "sters i", weight: 9, count: 4},
    "-1842810065:1006692915:1916229172": {ids: [-1842810065, 1006692915, 1916229172], regex: "sters in", weight: 10, count: 3},
    "-1842810065:-525989649:-34164001:355492922:927184680:1653061490:1855646823": {ids: [-1842810065, -525989649, -34164001, 355492922, 927184680, 1653061490, 1855646823], regex: "e monsters", weight: 12, count: 7},
    "-1842810065:-525989649:-34164001:355492922:927184680:1653061490": {ids: [-1842810065, -525989649, -34164001, 355492922, 927184680, 1653061490], regex: "e monsters", weight: 12, count: 6},
    "-1834985050:1197254574": {ids: [-1834985050, 1197254574], regex: "kn", weight: 2, count: 2},
    "-1834985050:229682870": {ids: [-1834985050, 229682870], regex: "vu", weight: 2, count: 2},
    "-1834985050:-1331734856:-500015770:1261702218": {ids: [-1834985050, -1331734856, -500015770, 1261702218], regex: "hai", weight: 3, count: 4},
    "-1834985050:1261702218": {ids: [-1834985050, 1261702218], regex: "mpo", weight: 3, count: 2},
    "-1834985050:-1331734856:1261702218": {ids: [-1834985050, -1331734856, 1261702218], regex: "hai", weight: 3, count: 3},
    "-1834985050:-1331734856:-500015770": {ids: [-1834985050, -1331734856, -500015770], regex: "hai", weight: 3, count: 3},
    "-1834985050:-1636388574:-696963131:-620427144:-412180168:1197254574:1303025587": {ids: [-1834985050, -1636388574, -696963131, -620427144, -412180168, 1197254574, 1303025587], regex: "ntal", weight: 4, count: 7},
    "-1834985050:-1636388574:-620427144:-412180168:1197254574:1275003885:1303025587": {ids: [-1834985050, -1636388574, -620427144, -412180168, 1197254574, 1275003885, 1303025587], regex: "ntal", weight: 4, count: 7},
    "-1834985050:-1636388574:-696963131:-620427144:-412180168:1275003885:1303025587": {ids: [-1834985050, -1636388574, -696963131, -620427144, -412180168, 1275003885, 1303025587], regex: "ntal", weight: 4, count: 7},
    "-1834985050:-1705341052:1197254574": {ids: [-1834985050, -1705341052, 1197254574], regex: "with e", weight: 8, count: 3},
    "-1834985050:-1331734856": {ids: [-1834985050, -1331734856], regex: "with t", weight: 8, count: 2},
    "-1834985050:-1705341052": {ids: [-1834985050, -1705341052], regex: "with e", weight: 8, count: 2},
    "-1834985050:-1705341052:-1660701657:310013779:1594594160:2075940345": {ids: [-1834985050, -1705341052, -1660701657, 310013779, 1594594160, 2075940345], regex: "yers a", weight: 8, count: 6},
    "-1834985050:-1705341052:-1660701657:310013779:1449891900:2075940345": {ids: [-1834985050, -1705341052, -1660701657, 310013779, 1449891900, 2075940345], regex: "rs are", weight: 8, count: 6},
    "-1834985050:-1705341052:229682870:1197254574:1261702218": {ids: [-1834985050, -1705341052, 229682870, 1197254574, 1261702218], regex: "s are c", weight: 9, count: 5},
    "-1834985050:-1705341052:-1660701657:310013779:2075940345": {ids: [-1834985050, -1705341052, -1660701657, 310013779, 2075940345], regex: "yers ar", weight: 9, count: 5},
    "-1781581610:-1660701657:-120302365:1349832036": {ids: [-1781581610, -1660701657, -120302365, 1349832036], regex: "by a", weight: 6, count: 4},
    "-1781581610:-1660701657:-120302365": {ids: [-1781581610, -1660701657, -120302365], regex: "ted by a", weight: 10, count: 3},
    "-1772227908:1245770343": {ids: [-1772227908, 1245770343], regex: "cs", weight: 2, count: 2},
    "-1772227908:-818452956:-525989649:511980950:1245770343": {ids: [-1772227908, -818452956, -525989649, 511980950, 1245770343], regex: "ana", weight: 3, count: 5},
    "-1772227908:1965894066:2023345436": {ids: [-1772227908, 1965894066, 2023345436], regex: "by s", weight: 6, count: 3},
    "-1678942184:-710235370:525934570:793356205:1515846394:1741763812:2023345436": {ids: [-1678942184, -710235370, 525934570, 793356205, 1515846394, 1741763812, 2023345436], regex: "tc", weight: 2, count: 7},
    "-1678942184:-710235370:525934570:793356205:1515846394:1741763812": {ids: [-1678942184, -710235370, 525934570, 793356205, 1515846394, 1741763812], regex: "atc", weight: 3, count: 6},
    "-1678942184:-710235370:525934570:793356205:1515846394:1741763812:2042799595": {ids: [-1678942184, -710235370, 525934570, 793356205, 1515846394, 1741763812, 2042799595], regex: "ea h", weight: 6, count: 7},
    "-1678942184:525934570": {ids: [-1678942184, 525934570], regex: "s of c", weight: 8, count: 2},
    "-1678942184:793356205": {ids: [-1678942184, 793356205], regex: "ted gro", weight: 9, count: 2},
    "-1660701657:1909893191": {ids: [-1660701657, 1909893191], regex: "fla", weight: 3, count: 2},
    "-1660701657:-1331734856:1177412437:1769059225": {ids: [-1660701657, -1331734856, 1177412437, 1769059225], regex: "when", weight: 4, count: 4},
    "-1636388574:-620427144:894187505:1275003885:1349832036": {ids: [-1636388574, -620427144, 894187505, 1275003885, 1349832036], regex: "sis", weight: 3, count: 5},
    "-1636388574:-50320413": {ids: [-1636388574, -50320413], regex: "uct", weight: 3, count: 2},
    "-1636388574:-620427144:894187505:1275003885": {ids: [-1636388574, -620427144, 894187505, 1275003885], regex: "sist", weight: 4, count: 4},
    "-1636388574:-50320413:762130113:1006692915:1177412437": {ids: [-1636388574, -50320413, 762130113, 1006692915, 1177412437], regex: "r ph", weight: 6, count: 5},
    "-1636388574:1275003885": {ids: [-1636388574, 1275003885], regex: "r el", weight: 6, count: 2},
    "-1636388574:-50320413:1275003885": {ids: [-1636388574, -50320413, 1275003885], regex: "% mon", weight: 7, count: 3},
    "-1636388574:-620427144:1275003885": {ids: [-1636388574, -620427144, 1275003885], regex: "tal r", weight: 7, count: 3},
    "-1636388574:-620427144": {ids: [-1636388574, -620427144], regex: "tal r", weight: 7, count: 2},
    "-1636388574:-1462808973:550943268:550943268:1275003885": {ids: [-1636388574, -1462808973, 550943268, 550943268, 1275003885], regex: "ster c", weight: 8, count: 5},
    "-1636388574:-1462808973:550943268:1275003885:1537433645": {ids: [-1636388574, -1462808973, 550943268, 1275003885, 1537433645], regex: "ster c", weight: 8, count: 5},
    "-1636388574:-1462808973:550943268:550943268:1275003885:1537433645": {ids: [-1636388574, -1462808973, 550943268, 550943268, 1275003885, 1537433645], regex: "ster c", weight: 8, count: 6},
    "-1636388574:-1462808973:550943268:550943268:1537433645": {ids: [-1636388574, -1462808973, 550943268, 550943268, 1537433645], regex: "ster c", weight: 8, count: 5},
    "-1636388574:550943268:550943268:1275003885:1537433645": {ids: [-1636388574, 550943268, 550943268, 1275003885, 1537433645], regex: "ster c", weight: 8, count: 5},
    "-1621229119:-818452956:-529767307:-208379648:581472251:1809097991": {ids: [-1621229119, -818452956, -529767307, -208379648, 581472251, 1809097991], regex: "nno", weight: 3, count: 6},
    "-1621229119:511980950": {ids: [-1621229119, 511980950], regex: "eec", weight: 3, count: 2},
    "-1621229119:511980950:1822260921": {ids: [-1621229119, 511980950, 1822260921], regex: "lee", weight: 3, count: 3},
    "-1621229119:-529767307:-208379648:581472251": {ids: [-1621229119, -529767307, -208379648, 581472251], regex: "ot b", weight: 6, count: 4},
    "-1621229119:-525989649:511980950": {ids: [-1621229119, -525989649, 511980950], regex: "d from", weight: 8, count: 3},
    "-1505174556:-926892006:-818452956:-525989649:164309504:511980950": {ids: [-1505174556, -926892006, -818452956, -525989649, 164309504, 511980950], regex: "gy", weight: 2, count: 6},
    "-1505174556:-818452956:-525989649:164309504:511980950": {ids: [-1505174556, -818452956, -525989649, 164309504, 511980950], regex: "gy", weight: 2, count: 5},
    "-1505174556:-1129414698:-926892006:-474049160:511980950:894187505:1769059225": {ids: [-1505174556, -1129414698, -926892006, -474049160, 511980950, 894187505, 1769059225], regex: "max", weight: 3, count: 7},
    "-1505174556:-818452956:-710235370:-620427144:-525989649:164309504:511980950": {ids: [-1505174556, -818452956, -710235370, -620427144, -525989649, 164309504, 511980950], regex: "ene", weight: 3, count: 7},
    "-1505174556:-926892006:-818452956:-710235370:-525989649:164309504:511980950": {ids: [-1505174556, -926892006, -818452956, -710235370, -525989649, 164309504, 511980950], regex: "ener", weight: 4, count: 7},
    "-1505174556:-818452956:-710235370:-525989649:164309504:511980950": {ids: [-1505174556, -818452956, -710235370, -525989649, 164309504, 511980950], regex: "ener", weight: 4, count: 6},
    "-1505174556:-926892006:164309504": {ids: [-1505174556, -926892006, 164309504], regex: "fe a", weight: 6, count: 3},
    "-1505174556:-926892006:762130113:1006692915": {ids: [-1505174556, -926892006, 762130113, 1006692915], regex: "as e", weight: 6, count: 4},
    "-1505174556:-926892006:-151531429:667938191:762130113:1006692915:1270766659": {ids: [-1505174556, -926892006, -151531429, 667938191, 762130113, 1006692915, 1270766659], regex: "s ex", weight: 6, count: 7},
    "-1505174556:164309504": {ids: [-1505174556, 164309504], regex: "fe a", weight: 6, count: 2},
    "-1505174556:1769059225": {ids: [-1505174556, 1769059225], regex: "m en", weight: 6, count: 2},
    "-1505174556:-926892006:1769059225": {ids: [-1505174556, -926892006, 1769059225], regex: "m en", weight: 6, count: 3},
    "-1505174556:-926892006:762130113:1006692915:1909893191": {ids: [-1505174556, -926892006, 762130113, 1006692915, 1909893191], regex: "in \\d+%", weight: 10, count: 5},
    "-1505174556:-1331734856:-926892006:-500015770:762130113:1006692915:1909893191": {ids: [-1505174556, -1331734856, -926892006, -500015770, 762130113, 1006692915, 1909893191], regex: "ain \\d+", weight: 10, count: 7},
    "-1462808973:577057701:1537433645": {ids: [-1462808973, 577057701, 1537433645], regex: "ik", weight: 2, count: 3},
    "-1462808973:1177412437": {ids: [-1462808973, 1177412437], regex: "mul", weight: 3, count: 2},
    "-1462808973:1177412437:1537433645": {ids: [-1462808973, 1177412437, 1537433645], regex: "mul", weight: 3, count: 3},
    "-1462808973:-894195682:638612957:1141186022:1434328288": {ids: [-1462808973, -894195682, 638612957, 1141186022, 1434328288], regex: "e \\d+% i", weight: 11, count: 5},
    "-1462808973:-894195682:638612957:1141186022:1434328288:1537433645": {ids: [-1462808973, -894195682, 638612957, 1141186022, 1434328288, 1537433645], regex: "e \\d+% i", weight: 11, count: 6},
    "-1462808973:-894195682:638612957:1141186022:1537433645": {ids: [-1462808973, -894195682, 638612957, 1141186022, 1537433645], regex: "e \\d+% i", weight: 11, count: 5},
    "-1403235912:-529767307:581472251:920184875": {ids: [-1403235912, -529767307, 581472251, 920184875], regex: "o b", weight: 5, count: 4},
    "-1403235912:920184875": {ids: [-1403235912, 920184875], regex: "o bl", weight: 6, count: 2},
    "-1403235912:60084997:1523693331:1936863142:2128933960": {ids: [-1403235912, 60084997, 1523693331, 1936863142, 2128933960], regex: "% less a", weight: 10, count: 5},
    "-1403235912:60084997:1936863142:2128933960": {ids: [-1403235912, 60084997, 1936863142, 2128933960], regex: "% less a", weight: 10, count: 4},
    "-1403235912:60084997:1523693331:2128933960": {ids: [-1403235912, 60084997, 1523693331, 2128933960], regex: "% less a", weight: 10, count: 4},
    "-1403235912:1523693331:1936863142": {ids: [-1403235912, 1523693331, 1936863142], regex: "% less ar", weight: 11, count: 3},
    "-1403235912:1936863142": {ids: [-1403235912, 1936863142], regex: "% less ar", weight: 11, count: 2},
    "-1403235912:-669857265:60084997:164309504:729842044:1936863142:2128933960": {ids: [-1403235912, -669857265, 60084997, 164309504, 729842044, 1936863142, 2128933960], regex: "e \\d+% l", weight: 11, count: 7},
    "-1403235912:1523693331": {ids: [-1403235912, 1523693331], regex: "% less ar", weight: 11, count: 2},
    "-1403235912:-669857265:60084997:164309504:729842044:1523693331:2128933960": {ids: [-1403235912, -669857265, 60084997, 164309504, 729842044, 1523693331, 2128933960], regex: "e \\d+% l", weight: 11, count: 7},
    "-1331734856:-894195682:1424932226": {ids: [-1331734856, -894195682, 1424932226], regex: "oj", weight: 2, count: 3},
    "-1331734856:-894195682": {ids: [-1331734856, -894195682], regex: "oj", weight: 2, count: 2},
    "-1331734856:-894195682:-34164001:1424932226": {ids: [-1331734856, -894195682, -34164001, 1424932226], regex: "til", weight: 3, count: 4},
    "-1331734856:-894195682:-34164001": {ids: [-1331734856, -894195682, -34164001], regex: "til", weight: 3, count: 3},
    "-1331734856:-1033371824:-500015770:1514873663": {ids: [-1331734856, -1033371824, -500015770, 1514873663], regex: "ills", weight: 4, count: 4},
    "-1331734856:-500015770": {ids: [-1331734856, -500015770], regex: "imes", weight: 4, count: 2},
    "-1331734856:-1033371824:1514873663": {ids: [-1331734856, -1033371824, 1514873663], regex: "ills", weight: 4, count: 3},
    "-1129414698:-1036254613:-474049160:719111530:1088280684": {ids: [-1129414698, -1036254613, -474049160, 719111530, 1088280684], regex: "ge on", weight: 7, count: 5},
    "-1129414698:719111530": {ids: [-1129414698, 719111530], regex: "a pow", weight: 7, count: 2},
    "-1129414698:-474049160:1769059225": {ids: [-1129414698, -474049160, 1769059225], regex: "o max", weight: 7, count: 3},
    "-1129414698:-474049160:719111530:1088280684": {ids: [-1129414698, -474049160, 719111530, 1088280684], regex: "ge on", weight: 7, count: 4},
    "-1129414698:-1036254613:-474049160:1088280684": {ids: [-1129414698, -1036254613, -474049160, 1088280684], regex: "ge on", weight: 7, count: 4},
    "-1129414698:-1036254613:-474049160:719111530:1088280684:1769059225": {ids: [-1129414698, -1036254613, -474049160, 719111530, 1088280684, 1769059225], regex: "gain a", weight: 8, count: 6},
    "-1129414698:-474049160:719111530:1088280684:1769059225": {ids: [-1129414698, -474049160, 719111530, 1088280684, 1769059225], regex: "gain a", weight: 8, count: 5},
    "-1129414698:-1036254613:-474049160:1088280684:1769059225": {ids: [-1129414698, -1036254613, -474049160, 1088280684, 1769059225], regex: "gain a", weight: 8, count: 5},
    "-1121411477:-1121327683:-620427144:1546387363": {ids: [-1121411477, -1121327683, -620427144, 1546387363], regex: "% e", weight: 5, count: 4},
    "-1121411477:-1121327683:1546387363": {ids: [-1121411477, -1121327683, 1546387363], regex: "% ex", weight: 6, count: 3},
    "-1121411477:-1121327683:762130113:1006692915:1546387363": {ids: [-1121411477, -1121327683, 762130113, 1006692915, 1546387363], regex: "ge as", weight: 7, count: 5},
    "-1121327683:-894195682:1424932226": {ids: [-1121327683, -894195682, 1424932226], regex: "fir", weight: 3, count: 3},
    "-1121327683:-894195682": {ids: [-1121327683, -894195682], regex: "fir", weight: 3, count: 2},
    "-1121327683:1514873663": {ids: [-1121327683, 1514873663], regex: "as f", weight: 6, count: 2},
    "-1036254613:-474049160": {ids: [-1036254613, -474049160], regex: "zy c", weight: 6, count: 2},
    "-1036254613:-474049160:1177412437": {ids: [-1036254613, -474049160, 1177412437], regex: "n a f", weight: 7, count: 3},
    "-1033371824:1741763812": {ids: [-1033371824, 1741763812], regex: "whi", weight: 3, count: 2},
    "-1033371824:-525989649:1594594160": {ids: [-1033371824, -525989649, 1594594160], regex: "r mi", weight: 6, count: 3},
    "-926892006:-818452956:-525989649:164309504:511980950": {ids: [-926892006, -818452956, -525989649, 164309504, 511980950], regex: "gy", weight: 2, count: 5},
    "-926892006:-818452956:-710235370:-620427144:-525989649:164309504:511980950": {ids: [-926892006, -818452956, -710235370, -620427144, -525989649, 164309504, 511980950], regex: "ene", weight: 3, count: 7},
    "-926892006:-818452956:-710235370:-525989649:164309504:511980950": {ids: [-926892006, -818452956, -710235370, -525989649, 164309504, 511980950], regex: "ener", weight: 4, count: 6},
    "-926892006:164309504": {ids: [-926892006, 164309504], regex: "fe a", weight: 6, count: 2},
    "-926892006:1769059225": {ids: [-926892006, 1769059225], regex: "m en", weight: 6, count: 2},
    "-894195682:493223943:1434328288:1514873663:1523693331:1691639998:1936863142": {ids: [-894195682, 493223943, 1434328288, 1514873663, 1523693331, 1691639998, 1936863142], regex: "eff", weight: 3, count: 7},
    "-894195682:493223943:1434328288:1514873663:1691639998:1936863142": {ids: [-894195682, 493223943, 1434328288, 1514873663, 1691639998, 1936863142], regex: "eff", weight: 3, count: 6},
    "-894195682:493223943:1514873663:1523693331:1691639998:1936863142": {ids: [-894195682, 493223943, 1514873663, 1523693331, 1691639998, 1936863142], regex: "eff", weight: 3, count: 6},
    "-894195682:493223943:1434328288:1514873663:1523693331:1691639998": {ids: [-894195682, 493223943, 1434328288, 1514873663, 1523693331, 1691639998], regex: "eff", weight: 3, count: 6},
    "-894195682:-696963131:-412180168:1434328288:1594594160:1691639998:1936863142": {ids: [-894195682, -696963131, -412180168, 1434328288, 1594594160, 1691639998, 1936863142], regex: "f e", weight: 5, count: 7},
    "-894195682:-696963131:-412180168:1523693331:1594594160:1691639998:1936863142": {ids: [-894195682, -696963131, -412180168, 1523693331, 1594594160, 1691639998, 1936863142], regex: "f e", weight: 5, count: 7},
    "-894195682:-412180168:1434328288:1523693331:1594594160:1691639998:1936863142": {ids: [-894195682, -412180168, 1434328288, 1523693331, 1594594160, 1691639998, 1936863142], regex: "f e", weight: 5, count: 7},
    "-894195682:-696963131:-412180168:1434328288:1523693331:1594594160:1691639998": {ids: [-894195682, -696963131, -412180168, 1434328288, 1523693331, 1594594160, 1691639998], regex: "f e", weight: 5, count: 7},
    "-894195682:1434328288:1523693331:1691639998:1936863142": {ids: [-894195682, 1434328288, 1523693331, 1691639998, 1936863142], regex: "ea o", weight: 6, count: 5},
    "-894195682:1434328288:1691639998:1936863142": {ids: [-894195682, 1434328288, 1691639998, 1936863142], regex: "ea o", weight: 6, count: 4},
    "-894195682:1523693331:1691639998:1936863142": {ids: [-894195682, 1523693331, 1691639998, 1936863142], regex: "ea o", weight: 6, count: 4},
    "-894195682:1434328288:1523693331:1691639998": {ids: [-894195682, 1434328288, 1523693331, 1691639998], regex: "ea o", weight: 6, count: 4},
    "-894195682:1424932226": {ids: [-894195682, 1424932226], regex: "rs fi", weight: 7, count: 2},
    "-894195682:1434328288:1691639998": {ids: [-894195682, 1434328288, 1691639998], regex: "d are", weight: 7, count: 3},
    "-894195682:1691639998": {ids: [-894195682, 1691639998], regex: "d are", weight: 7, count: 2},
    "-894195682:638612957:1434328288:1691639998:2043204851": {ids: [-894195682, 638612957, 1434328288, 1691639998, 2043204851], regex: "ased a", weight: 8, count: 5},
    "-894195682:638612957:1691639998:2043204851": {ids: [-894195682, 638612957, 1691639998, 2043204851], regex: "ased a", weight: 8, count: 4},
    "-894195682:-151531429:667938191:1270766659:1424932226": {ids: [-894195682, -151531429, 667938191, 1270766659, 1424932226], regex: "re \\d+", weight: 9, count: 5},
    "-894195682:-151531429:667938191:1424932226": {ids: [-894195682, -151531429, 667938191, 1424932226], regex: "re \\d+", weight: 9, count: 4},
    "-894195682:-151531429:1270766659:1424932226": {ids: [-894195682, -151531429, 1270766659, 1424932226], regex: "re \\d+", weight: 9, count: 4},
    "-894195682:-151531429:667938191:1270766659": {ids: [-894195682, -151531429, 667938191, 1270766659], regex: "re \\d+", weight: 9, count: 4},
    "-894195682:927184680:1424932226": {ids: [-894195682, 927184680, 1424932226], regex: "e \\d+ a", weight: 10, count: 3},
    "-894195682:927184680": {ids: [-894195682, 927184680], regex: "e \\d+ a", weight: 10, count: 2},
    "-894195682:638612957:1141186022:1434328288:1537433645": {ids: [-894195682, 638612957, 1141186022, 1434328288, 1537433645], regex: "e \\d+% i", weight: 11, count: 5},
    "-818452956:-525989649:511980950": {ids: [-818452956, -525989649, 511980950], regex: "fe,", weight: 3, count: 3},
    "-818452956:1809097991": {ids: [-818452956, 1809097991], regex: "yers c", weight: 8, count: 2},
    "-710235370:-34164001": {ids: [-710235370, -34164001], regex: "lati", weight: 4, count: 2},
    "-704095924:-208379648": {ids: [-704095924, -208379648], regex: "r li", weight: 6, count: 2},
    "-696963131:-412180168:1177412437:1734526203": {ids: [-696963131, -412180168, 1177412437, 1734526203], regex: "efl", weight: 3, count: 4},
    "-696963131:-412180168": {ids: [-696963131, -412180168], regex: "f el", weight: 6, count: 2},
    "-696963131:-412180168:1734526203": {ids: [-696963131, -412180168, 1734526203], regex: "s refl", weight: 8, count: 3},
    "-696963131:-412180168:1734526203:1916229172": {ids: [-696963131, -412180168, 1734526203, 1916229172], regex: "t \\d+", weight: 8, count: 4},
    "-696963131:-525989649:-412180168:1734526203": {ids: [-696963131, -525989649, -412180168, 1734526203], regex: "sters r", weight: 9, count: 4},
    "-620427144:200974414": {ids: [-620427144, 200974414], regex: "tes", weight: 3, count: 2},
    "-620427144:638612957:1204424670": {ids: [-620427144, 638612957, 1204424670], regex: "mage p", weight: 8, count: 3},
    "-620427144:1691639998:2043204851": {ids: [-620427144, 1691639998, 2043204851], regex: "s \\d+%", weight: 9, count: 3},
    "-529767307:581472251": {ids: [-529767307, 581472251], regex: "elo", weight: 3, count: 2},
    "-529767307:581472251:2128933960": {ids: [-529767307, 581472251, 2128933960], regex: "' m", weight: 5, count: 3},
    "-525989649:762130113:1006692915:1177412437:1204424670:1594594160:2023345436": {ids: [-525989649, 762130113, 1006692915, 1177412437, 1204424670, 1594594160, 2023345436], regex: "ei", weight: 2, count: 7},
    "-525989649:1594594160:2128933960": {ids: [-525989649, 1594594160, 2128933960], regex: "nio", weight: 3, count: 3},
    "-525989649:1177412437": {ids: [-525989649, 1177412437], regex: "emov", weight: 4, count: 2},
    "-525989649:164309504": {ids: [-525989649, 164309504], regex: "f l", weight: 5, count: 2},
    "-525989649:310013779:1653061490": {ids: [-525989649, 310013779, 1653061490], regex: "ue m", weight: 6, count: 3},
    "-525989649:511980950": {ids: [-525989649, 511980950], regex: "na a", weight: 6, count: 2},
    "-525989649:164309504:511980950": {ids: [-525989649, 164309504, 511980950], regex: "d ene", weight: 7, count: 3},
    "-525989649:1594594160": {ids: [-525989649, 1594594160], regex: "eir m", weight: 7, count: 2},
    "-525989649:-412180168": {ids: [-525989649, -412180168], regex: "sters r", weight: 9, count: 2},
    "-474049160:1177412437": {ids: [-474049160, 1177412437], regex: "n a f", weight: 7, count: 2},
    "-412180168:1177412437": {ids: [-412180168, 1177412437], regex: "efl", weight: 3, count: 2},
    "-412180168:1734526203": {ids: [-412180168, 1734526203], regex: "f ph", weight: 6, count: 2},
    "-412180168:1916229172": {ids: [-412180168, 1916229172], regex: "t \\d+", weight: 8, count: 2},
    "-208379648:581472251": {ids: [-208379648, 581472251], regex: "tun", weight: 3, count: 2},
    "-208379648:694566327": {ids: [-208379648, 694566327], regex: "r li", weight: 6, count: 2},
    "-151531429:667938191": {ids: [-151531429, 667938191], regex: "fs", weight: 2, count: 2},
    "-151531429:667938191:1653061490": {ids: [-151531429, 667938191, 1653061490], regex: "uf", weight: 2, count: 3},
    "-151531429:1270766659": {ids: [-151531429, 1270766659], regex: "fs", weight: 2, count: 2},
    "-151531429:1270766659:1653061490": {ids: [-151531429, 1270766659, 1653061490], regex: "uf", weight: 2, count: 3},
    "-151531429:667938191:1270766659": {ids: [-151531429, 667938191, 1270766659], regex: "fs", weight: 2, count: 3},
    "-151531429:667938191:1270766659:1653061490": {ids: [-151531429, 667938191, 1270766659, 1653061490], regex: "uf", weight: 2, count: 4},
    "-151531429:493223943": {ids: [-151531429, 493223943], regex: "n mon", weight: 7, count: 2},
    "-151531429:927184680": {ids: [-151531429, 927184680], regex: "sters e", weight: 9, count: 2},
    "-6327993:638612957:1652154666": {ids: [-6327993, 638612957, 1652154666], regex: "sup", weight: 3, count: 3},
    "-6327993:465825356:638612957:1652154666": {ids: [-6327993, 465825356, 638612957, 1652154666], regex: "pel", weight: 3, count: 4},
    "-6327993:638612957": {ids: [-6327993, 638612957], regex: "sup", weight: 3, count: 2},
    "-6327993:465825356:638612957": {ids: [-6327993, 465825356, 638612957], regex: "pel", weight: 3, count: 3},
    "-6327993:638612957:1141186022:1652154666": {ids: [-6327993, 638612957, 1141186022, 1652154666], regex: "ll d", weight: 6, count: 4},
    "-6327993:638612957:1141186022": {ids: [-6327993, 638612957, 1141186022], regex: "ll d", weight: 6, count: 3},
    "60084997:638612957:1349832036": {ids: [60084997, 638612957, 1349832036], regex: "cc", weight: 2, count: 3},
    "60084997:638612957": {ids: [60084997, 638612957], regex: "ccu", weight: 3, count: 2},
    "60084997:164309504:638612957:729842044:1471007742": {ids: [60084997, 164309504, 638612957, 729842044, 1471007742], regex: "y ra", weight: 6, count: 5},
    "60084997:1349832036": {ids: [60084997, 1349832036], regex: "s ac", weight: 6, count: 2},
    "60084997:164309504:638612957:729842044": {ids: [60084997, 164309504, 638612957, 729842044], regex: "y rat", weight: 7, count: 4},
    "164309504:511980950:729842044": {ids: [164309504, 511980950, 729842044], regex: "reco", weight: 4, count: 3},
    "164309504:511980950:729842044:1594594160": {ids: [164309504, 511980950, 729842044, 1594594160], regex: "very", weight: 4, count: 4},
    "164309504:729842044": {ids: [164309504, 729842044], regex: "ry ra", weight: 7, count: 2},
    "190130927:755773474:1741763812": {ids: [190130927, 755773474, 1741763812], regex: "hoc", weight: 3, count: 3},
    "190130927:200974414:755773474": {ids: [190130927, 200974414, 755773474], regex: "ign", weight: 3, count: 3},
    "190130927:755773474": {ids: [190130927, 755773474], regex: "te,", weight: 3, count: 2},
    "190130927:200974414": {ids: [190130927, 200974414], regex: "s ig", weight: 6, count: 2},
    "190130927:200974414:894187505": {ids: [190130927, 200974414, 894187505], regex: "all m", weight: 7, count: 3},
    "200974414:1141186022:1177412437:1204424670": {ids: [200974414, 1141186022, 1177412437, 1204424670], regex: "hits", weight: 4, count: 4},
    "200974414:1204424670": {ids: [200974414, 1204424670], regex: "om h", weight: 6, count: 2},
    "200974414:577057701:1141186022:1594594160": {ids: [200974414, 577057701, 1141186022, 1594594160], regex: "mage f", weight: 8, count: 4},
    "200974414:577057701:1141186022:1204424670": {ids: [200974414, 577057701, 1141186022, 1204424670], regex: "e from", weight: 8, count: 4},
    "200974414:577057701:1141186022": {ids: [200974414, 577057701, 1141186022], regex: "mage fr", weight: 9, count: 3},
    "310013779:1177412437": {ids: [310013779, 1177412437], regex: "ft", weight: 2, count: 2},
    "310013779:1741763812": {ids: [310013779, 1741763812], regex: "ked", weight: 3, count: 2},
    "310013779:762130113:1653061490": {ids: [310013779, 762130113, 1653061490], regex: "a ra", weight: 6, count: 3},
    "310013779:1006692915:1594594160": {ids: [310013779, 1006692915, 1594594160], regex: "r \\d+", weight: 8, count: 3},
    "310013779:1006692915": {ids: [310013779, 1006692915], regex: "r \\d+ s", weight: 10, count: 2},
    "355492922:577057701": {ids: [355492922, 577057701], regex: "sters t", weight: 9, count: 2},
    "465825356:638612957:1652154666": {ids: [465825356, 638612957, 1652154666], regex: "pel", weight: 3, count: 3},
    "465825356:884783454:1177412437": {ids: [465825356, 884783454, 1177412437], regex: "hit w", weight: 7, count: 3},
    "465825356:884783454": {ids: [465825356, 884783454], regex: "hit wi", weight: 8, count: 2},
    "493223943:1514873663": {ids: [493223943, 1514873663], regex: "ect o", weight: 7, count: 2},
    "550943268:2128933960": {ids: [550943268, 2128933960], regex: "ck sp", weight: 7, count: 2},
    "550943268:2043204851:2128933960": {ids: [550943268, 2043204851, 2128933960], regex: "ast s", weight: 7, count: 3},
    "550943268:550943268:2043204851:2128933960": {ids: [550943268, 550943268, 2043204851, 2128933960], regex: "ast s", weight: 7, count: 4},
    "550943268:550943268:2128933960": {ids: [550943268, 550943268, 2128933960], regex: "ck sp", weight: 7, count: 3},
    "577057701:1204424670:1741763812": {ids: [577057701, 1204424670, 1741763812], regex: "tak", weight: 3, count: 3},
    "577057701:1204424670": {ids: [577057701, 1204424670], regex: "s ta", weight: 6, count: 2},
    "577057701:762130113": {ids: [577057701, 762130113], regex: "ra d", weight: 6, count: 2},
    "577057701:1514873663": {ids: [577057701, 1514873663], regex: "ced e", weight: 7, count: 2},
    "638612957:1652154666": {ids: [638612957, 1652154666], regex: "sup", weight: 3, count: 2},
    "638612957:1141186022:1652154666": {ids: [638612957, 1141186022, 1652154666], regex: "ll d", weight: 6, count: 3},
    "638612957:894187505": {ids: [638612957, 894187505], regex: "% to a", weight: 8, count: 2},
    "729842044:2128933960": {ids: [729842044, 2128933960], regex: "% less c", weight: 10, count: 2},
    "755773474:1653061490:1822260921": {ids: [755773474, 1653061490, 1822260921], regex: "ve a", weight: 6, count: 3},
    "755773474:1822260921": {ids: [755773474, 1822260921], regex: "a \\d+", weight: 8, count: 2},
    "762130113:1653061490": {ids: [762130113, 1653061490], regex: "ndom", weight: 4, count: 2},
    "762130113:1006692915:1177412437": {ids: [762130113, 1006692915, 1177412437], regex: "ir p", weight: 6, count: 3},
    "762130113:1006692915": {ids: [762130113, 1006692915], regex: "% of t", weight: 8, count: 2},
    "793356205:1204424670": {ids: [793356205, 1204424670], regex: "f d", weight: 5, count: 2},
    "856370140:1177412437:1515846394:1546387363": {ids: [856370140, 1177412437, 1515846394, 1546387363], regex: "ning", weight: 4, count: 4},
    "920184875:2043204851": {ids: [920184875, 2043204851], regex: "ck a", weight: 6, count: 2},
    "927184680:1855646823": {ids: [927184680, 1855646823], regex: "nu", weight: 2, count: 2},
    "931213255:1409464152": {ids: [931213255, 1409464152], regex: "by g", weight: 6, count: 2},
    "1006692915:1177412437:1809097991:1916229172": {ids: [1006692915, 1177412437, 1809097991, 1916229172], regex: "nfl", weight: 3, count: 4},
    "1006692915:1177412437:1916229172": {ids: [1006692915, 1177412437, 1916229172], regex: "s inf", weight: 7, count: 3},
    "1006692915:1916229172": {ids: [1006692915, 1916229172], regex: "rs inf", weight: 8, count: 2},
    "1088280684:1769059225": {ids: [1088280684, 1769059225], regex: "an en", weight: 7, count: 2},
    "1141186022:1687576035:1822260921": {ids: [1141186022, 1687576035, 1822260921], regex: "iso", weight: 3, count: 3},
    "1141186022:1822260921": {ids: [1141186022, 1822260921], regex: "iso", weight: 3, count: 2},
    "1141186022:1687576035": {ids: [1141186022, 1687576035], regex: "son o", weight: 7, count: 2},
    "1177412437:1537433645": {ids: [1177412437, 1537433645], regex: "mul", weight: 3, count: 2},
    "1177412437:1822260921": {ids: [1177412437, 1822260921], regex: ", i", weight: 5, count: 2},
    "1177412437:1594594160:2023345436": {ids: [1177412437, 1594594160, 2023345436], regex: "d th", weight: 6, count: 3},
    "1177412437:1349832036": {ids: [1177412437, 1349832036], regex: "ied by", weight: 8, count: 2},
    "1204424670:1741763812": {ids: [1204424670, 1741763812], regex: "taken", weight: 5, count: 2},
    "1204424670:2128933960": {ids: [1204424670, 2128933960], regex: "yers'", weight: 5, count: 2},
    "1204424670:1546387363": {ids: [1204424670, 1546387363], regex: "s li", weight: 6, count: 2},
    "1204424670:2023345436": {ids: [1204424670, 2023345436], regex: "ir s", weight: 6, count: 2},
    "1204424670:1349832036": {ids: [1204424670, 1349832036], regex: "s is", weight: 6, count: 2},
    "1303025587:1822260921": {ids: [1303025587, 1822260921], regex: "avo", weight: 3, count: 2},
    "1514873663:1653061490": {ids: [1514873663, 1653061490], regex: "om s", weight: 6, count: 2},
    "1594594160:2023345436": {ids: [1594594160, 2023345436], regex: "s and", weight: 7, count: 2},
    "1691639998:2043204851": {ids: [1691639998, 2043204851], regex: "oss h", weight: 7, count: 2},
  },
}